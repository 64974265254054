<template>
	<div>
		<div class="house-info">
			<div :style="noDetails ? 'float: left;' : ''" class="title">
				<Tooltip theme="light" max-width="900" style="width: 100%" :content="activeName" word-wrap
					placement="bottom">{{ activeName }}</Tooltip>
			</div>
			<Button title="切换企业" size="large" style="color:#333333;font-size: 20px;" ghost @click="handleOpenModal" v-if="noDetails">
				<Icon type="md-swap" />
			</Button>
			<Tag size="large" class="selfBtn" type="dot" color="#398EFF" v-if="componeyData.sszx && noDetails">
				{{componeyData.sszx}}
			</Tag>
			<Tag size="large" class="selfBtn" type="dot" color="#2FC83D" v-if="componeyData.ssgs && noDetails">
				{{ componeyData.ssgs}}
			</Tag>
			<Tag size="large" class="selfBtn" type="dot" color="#9E9EFF"
				v-if="componeyData.ssqk && noDetails && componeyData.ssqk != '非上市'">
				{{ componeyData.ssqk}}
			</Tag>
		</div>
		<Modal v-model="modal_info" draggable scrollable title @on-ok="handleOk" @on-cancel="handleCancel">
			<div>
				<Input v-model="requestParam.q" placeholder="输入关键字搜索" style="width: 400px" search @input="handleSearch"
					@on-enter="handleSearch" />
				<Button type="info" @click="handleSearch">搜索</Button>
			</div>
			<Row class="type_content">
				<Col span="24">
				<Card class="type_box">
					<div>
						<Row>
							<Col span="8">
							<RadioGroup v-model="type" @on-change="handleChangeType" class="radio_type" vertical>
								<Radio :label="item.name" v-for="(item, index) in list" :key="index">
									<span>{{ item.name }}</span>
								</Radio>
							</RadioGroup>
							</Col>
							<Col span="16">
							<div class="type_class">
								<template v-for="(item, index) in classList">
									<div v-if="item.gpmc.length === 0" :class="{ active: selectCode == item.gsmc }"
										:key="index" @click="
                          () =>
                            handleClickComponey(item.gsmc, item.gsmc, 'false')
                        ">
										{{ item.gsmc }}
									</div>
									<div v-else :class="{ active: selectCode == item.gpmc }" :key="index" @click="
                          () =>
                            handleClickComponey(item.gpmc, item.gsmc, 'true')
                        ">
										{{ item.gsmc }}
									</div>
								</template>
							</div>
							</Col>
						</Row>
					</div>
				</Card>
				</Col>
			</Row>
		</Modal>
		<router-view></router-view>
	</div>
</template>

<script>
	import {
		get_enterprise_dim_dimClassify,
		post_enterprise_gsxxType,
		getSshl,
	} from "@/api/houseEnterprise";
	export default {
		name: "houseEnterprise",
		components: {},
		data() {
			return {
				modal_info: false,
				showType: "房企",
				list: [],
				type: "",
				classList: [],
				activeCode: "",
				activeId: "",
				activeName: "",
				selectCode: "",
				selectName: "",
				componeyData: {},
				requestParam: {
					qylx: "",
					q: "",
				},
				isMarket: "true",
			};
		},
		computed: {
			noDetails() {
				const details = [
					"/Insight/enterprise/houseEnterprise/mergerAcquisitionDetails",
				];
				return !details.includes(this.$route.fullPath.split("?")[0]);
			},
			gpdm() {
				return this.$store.state.componeyCode;
			},
		},
		mounted() {
			this.$nextTick(function() {
				const isMarket = this.$route.query.isMarket;
				const code = this.$route.query.code || this.$store.state.componeyCode;
				if (this.$route.query.isMarket) {
					this.isMarket = this.$route.query.isMarket;
				} else if (/\d/.test(code)) {
					this.isMarket = true;
				} else {
					this.isMarket = "false";
				}
				const pathArray = this.$route.path.split("/");

				if (code && code.length !== 0) {
					this.init(pathArray[3], code, this.isMarket);
				} else {
					this.init(pathArray[3], "", this.isMarket);
				}
			});
		},
		watch: {
			// $route(to, from) {
			//   //监听路由是否变化
			//   const fromPathArray = from.path.split("/");
			//   const toPathArray = to.path.split("/");
			//   this.modal_info = false;
			//   if (fromPathArray[3] !== toPathArray[3]) {
			//     this.init(toPathArray[3], "", to.query.isMarket); //重新加载数据
			//   }
			// },
			gpdm(newVal, oldVal) {
				if (!newVal) {
					let path = this.$route.path.split("/");
					if (path[3] == "bank") {
						this.activeName = this.$route.query.code
						this.isMarket = this.$route.query.isMarket
					} else {
						this.activeName = this.$route.query.code;
						this.isMarket = this.$route.query.isMarket
					}
				}
			},
		},
		methods: {
			init(type, initCode, isMarket) {
				// 获取币种汇率
				this.getRate();

				const pathType = {
					houseEnterprise: {
						qylx: "房企",
						index: 1,
					},
					bank: {
						qylx: "银行",
						index: 2,
						renderIndex: 1,
					},
				};
				const renderType = pathType[type];
				(this.showType = renderType.qylx),
				(this.requestParam.qylx = renderType.qylx);
				this.requestParam.q = "";

				get_enterprise_dim_dimClassify(this.requestParam)
					.then((data) => {
						this.list = data;
						this.type = data[renderType.index] ? data[renderType.index].name : "";
						this.classList = data[renderType.index] ?
							data[renderType.index].list : [];
						let renderList = renderType.renderIndex ?
							data[renderType.index].list[renderType.renderIndex] :
							data[renderType.index].list[0];

						let contrastKey = "";
						let selectCode = "";
						let isNoCode = false;
						if (initCode.length !== 0) {
							data.map((item) => {
								for (let i = 0, len = item.list.length; i < len; i++) {
									if (isMarket == "false" || !isMarket) {
										contrastKey = item.list[i].gsmc;
										selectCode = "gsmc";

									} else {
										contrastKey = item.list[i].gpmc;
										isNoCode = false;
										selectCode = "gpmc";
									}
									if (initCode === contrastKey) {
										renderList = item.list[i];
										this.classList = item.list;
										this.type = item.name;
										this.selectCode = renderList[selectCode];
										this.activeCode = renderList[selectCode];
										this.activeName = renderList.gsmc;
										this.activeId = renderList.id;
										break;
									}
								}
							});
							this.gsxxType(initCode)
						} else {
							this.selectCode =
								renderList.gpmc.length !== 0 ? renderList.gpmc : renderList.gsmc;
							this.activeCode =
								renderList.gpmc.length !== 0 ? renderList.gpmc : renderList.gsmc;
							this.activeName = renderList.gsmc;
							this.activeId = renderList.id;
							this.gsxxType(this.selectCode)
						}

					})
					.catch((e) => {

					});
			},
			gsxxType(code) {
				post_enterprise_gsxxType({
						code: code
					})
					.then((listData) => {
						this.activeCode = this.selectCode;
						this.componeyData = listData;
						this.$store.commit("setComponeyCode", this.selectCode);
						this.$store.commit("setComponeyId", this.activeId);
					})
					.catch((e) => {

					});
			},
			getRate() {
				getSshl().then((res) => {
					const list = new Array(3);
					list[0] = {
						id: "rmb",
						rate: 1,
						name: "人民币",
					};

					list[1] = {
						id: "hb",
						rate: res.hb,
						name: "港币",
					};

					list[2] = {
						id: "usb",
						rate: res.usd,
						name: "美元",
					};

					this.$store.commit("changeState", {
						prop: "bzList",
						value: list,
					});
				});
			},

			handleOpenModal() {
				this.modal_info = true;
			},
			handleSearch() {
				if (this.requestParam.q.length === 0) {
					// this.$Message.warning(`关键词不能为空`);

					this.$msg.error({
						type: "remind",
						code: 4023
					});

					return false;
				}
				this.getDimClassify(this.requestParam);
			},
			getDimClassify(requestParam, type) {
				get_enterprise_dim_dimClassify(requestParam)
					.then((data) => {
						let listArray = data.filter((item) => item.list.length > 0);
						this.list = listArray;
						this.type = listArray[0] ? listArray[0].name : "";
						this.classList = listArray[0] ? listArray[0].list : [];
					})
					.catch((e) => {

					});
			},
			handleChangeType(name) {
				const {
					list
				} = this;
				list.map((item) => {
					if (item.name === name) {
						this.classList = item.list;
					}
				});
			},
			handleClickComponey(code, name, isMarket) {
				this.selectName = name;
				this.selectCode = code;
				this.isMarket = isMarket;
			},
			async handleOk(id) {
				let data
				if (id) {
					data = await post_enterprise_gsxxType({
						code: id
					});
				} else {
					data = await post_enterprise_gsxxType({
						code: this.selectCode
					});
				}
				this.activeName = this.selectName;
				this.activeCode = this.selectCode;
				this.componeyData = data;
				this.$store.commit("setComponeyCode", this.selectCode);
				this.$router.push({
					path: this.$route.path,
					query: {
						code: this.selectCode,
						isMarket: this.isMarket,
					},
				});
				this.requestParam.q = "";
				this.getDimClassify(this.requestParam);
			},
			handleCancel() {
				this.requestParam.q = "";
				this.getDimClassify(this.requestParam);
			},
		},
	};
</script>
<style lang="scss" scoped>
	.title {
		font-size: 20px;
		font-weight: 600;
		margin-right: 10px;
		// max-width: 200px;
		overflow: hidden;
		text-overflow: ellipsis;
		white-space: nowrap;
		line-height: 40px;
	}

	.type_header {
		border-radius: 2px;
		border: 1px solid rgba(214, 215, 217, 1);
		font-size: 16px;
		height: 58px;
		line-height: 58px;
		text-align: center;
		margin-bottom: 10px;
	}

	.type_header:hover,
	.type_header.active {
		background: #00b6ff;
		color: #fff;
	}

	.house-info {
		padding: 20px 20px 0;
		width: 100%;
	}

	.right-box {
		overflow: inherit;
	}

	.type_content {
		padding: 20px 0 0 0;
		margin-left: -10px;
	}

	.type_box {
		border: 1px solid #d6d7d9;
		background: rgba(246, 249, 255, 1);
		margin-left: 10px;
	}

	.type_class {
		height: 230px;
		overflow: auto;
		background: #fff;
		border-radius: 2px;
		border: 1px solid #d6d7d9;
		padding: 10px 20px;
	}

	.type_class>div {
		padding-top: 5px;
		padding-bottom: 5px;
		white-space: nowrap;
		text-overflow: ellipsis;
		overflow: hidden;
		cursor: pointer;
	}

	.type_class>div:hover,
	.type_class>div.active {
		color: #00b6ff;
	}

	.radio_type .ivu-radio-wrapper {
		padding: 10px 0;
	}

	.selfBtn {
		margin: 0 5px;
		border-radius: 8px;
	}
</style>
